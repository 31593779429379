.Title-logo {
	height: 80px !important;
	background-color: black !important;
	display: grid !important;
	justify-content: center !important;
	align-items: center !important;
	font-family: Stencil;
}

.Logo {
	font-family: Stencil;
	margin-top: 10px;
}

.Title-logo img {
	height: 50px !important;
}

.Title-Techcollege {
	background: white !important;
	height: 45px !important;
	display: grid !important;
	justify-content: center !important;
	align-items: center !important;
	border-bottom: 1px black solid !important;
}

.Title-Techcollege img {
	height: 100% !important;
}

.showNone {
	display: none;
}

.show {
	display: flex;
	border-bottom: 1px #cecece solid;
	padding: 10px 0px 10px 0px !important;
}
.Ion-toolbar {
	border-bottom: 2px #cecece solid;
	border-top: 2px #323232 solid;
}
.Ion-errors {
	margin-left: 15px;
	font-size: 1.1rem;
	text-transform: uppercase;
}

.Ion-divider {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 15px;
	position: relative;
}

.Ion-label {
	width: 100%;
	margin-top: 0 !important;
	font-size: 1.2rem !important;
	color: #fff !important;
	display: flex !important;
	justify-content: space-between !important;
}

.Ion-text {
	width: 100%;
	font-size: 1rem !important;
	color: #fff;
}

.Ion-list {
	padding: 0;
	border-bottom: 2px solid #cecece !important;
}

.Ion-col {
	padding: 0;
	white-space: pre-wrap;
}

.Ion-title {
	display: flex;
	flex-wrap: wrap;
}
.Ion-toolbar-modal {
	display: flex;
	flex-wrap: wrap;
	height: auto;
}

.Ion-divider::after {
	content: url("../img/pointerFinger.svg");
	display: inline-block;
	position: absolute;
	right: 7%;
	top: 50%;
	transform: translateY(-50%);
	width: 10vmax;
	max-width: 75px;
}

/* .Ion-col-message::after {
	content: url("../img/pointerFinger.svg");
	display: inline-block;
	width: 7%;
}
.Ion-col-message {
	display: flex !important;
	justify-content: space-between !important;
} */
